<template>
    <b-container fluid>
        <h3>Production line stages</h3>
        <b-card style="background-color: #ffffff;">
            <b-row class="justify-content-center">
                <b-col lg="12">
                    <div class="d-flex justify-content-center">
                        <div v-for="(stage, index) in stages" :key="stage.stage" class="stage">
                            <div class="line" v-if="index !== 0"></div>
                            <div class="circle" :style="getCircleStyle(stage.stage)">{{ stage.count }}</div>
                            <div class="name text-center">{{ stage.stage }}</div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <br>
        <h3>Crop distribution</h3>
        <b-card style="background-color: #ffffff;">
            <div class="crop-distribution">
                <div class="greenhouse-row">
                    <div class="crop-name"></div>
                    <div class="crop-header" v-for="(crop, index) in cropDistributionData.crops" :key="index"><strong>{{ crop }}</strong></div>
                </div>
                <div class="greenhouse-row">
                    <div class="crop-name">GH1</div>
<!--                    <div v-for="(value, index) in cropDistributionData.gh1" :style="getCropStyle(value.cropFamilyName)" :key="index" class="crop-value">{{ value.total }} %</div>-->
                    <div v-for="(crop, index) in cropDistributionData.crops" :style="getCropStyle(crop)" :key="index" class="crop-value">{{  cropDistributionData.gh1.find(value => value.cropFamilyName === crop) ? cropDistributionData.gh1.find(value => value.cropFamilyName === crop).total : 0 }} %</div>
                </div>
                <div class="greenhouse-row">
                    <div class="crop-name">GH2</div>
<!--                    <div  v-for="(value, index) in cropDistributionData.gh2" :style="getCropStyle(value.cropFamilyName)" :key="index" class="crop-value">{{ value.total }} %</div>-->
                    <div  v-for="(crop, index) in cropDistributionData.crops" :style="getCropStyle(crop)" :key="index" class="crop-value">{{ cropDistributionData.gh2.find(value => value.cropFamilyName === crop) ? cropDistributionData.gh2.find(value => value.cropFamilyName === crop).total : 0 }} %</div>
                </div>
                <div class="greenhouse-row">
                    <div class="crop-name">Average</div>
                    <div :style="getCropStyle('Empty')" class="crop-average">{{ cropDistributionData.average }} %</div>
                    <div :style="getCropStyle('Empty')" class="crop-value">{{ cropDistributionData.averageEmpty }} %</div>
                </div>
            </div>
        </b-card>
<!--        <br>-->
<!--        <h3>Crop occupancy</h3>-->
<!--        <b-card style="background-color: #ffffff;">-->
<!--            <b-row>-->
<!--                <b-col lg="6">-->
<!--                    <div class="crop-name">GH1</div>-->
<!--                    <b-row>-->
<!--                        <b-col lg="4" v-for="(compartment, index) in cropByCompartmentData.gh1" :key="index" class="mb-3">-->
<!--                            <div class="progress" style="height: 60px; width: 100%">-->
<!--                                <div style="position: absolute; top: 20%; padding-left: 8px;">-->
<!--                                    <strong>{{ compartment.nameCompartment }}</strong>-->
<!--                                </div>-->
<!--                                <div style="position: absolute; width: 100%; height: 100%; text-align: center; padding-right: 29px; top: 30%;">-->
<!--                                    <h2><strong>{{ compartment.occupancyPercent }} <small>%</small></strong></h2>-->
<!--                                </div>-->
<!--                                <div class="progress-bar bg-info" :style="'width: ' + compartment.occupancyPercent + '%;'"></div>-->
<!--                            </div>-->
<!--                        </b-col>-->
<!--                    </b-row>-->
<!--&lt;!&ndash;                    <div v-for="(compartment, index) in cropByCompartmentData.gh1" :key="index">&ndash;&gt;-->
<!--&lt;!&ndash;                        <b-card>&ndash;&gt;-->
<!--&lt;!&ndash;                            Compartment: {{ compartment.nameCompartment }} <br>&ndash;&gt;-->
<!--&lt;!&ndash;                            Percent occupancy: {{ compartment.occupancyPercent }} % <br>&ndash;&gt;-->
<!--&lt;!&ndash;                            Vacancy: {{ 100 -  compartment.occupancyPercent}} %&ndash;&gt;-->
<!--&lt;!&ndash;                        </b-card>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                </b-col>-->
<!--                <b-col lg="6">-->
<!--                    <div class="crop-name">GH2</div>-->
<!--                    <b-row>-->
<!--                        <b-col lg="4" v-for="(compartment, index) in cropByCompartmentData.gh2" :key="index" class="mb-3">-->
<!--                            <div class="progress" style="height: 60px; width: 100%">-->
<!--                                <div style="position: absolute; top: 20%; padding-left: 8px;">-->
<!--                                    <strong>{{ compartment.nameCompartment }}</strong>-->
<!--                                </div>-->
<!--                                <div style="position: absolute; width: 100%; height: 100%; text-align: center; padding-right: 29px; top: 30%;">-->
<!--                                    <h2><strong>{{ compartment.occupancyPercent }} <small>%</small></strong></h2>-->
<!--                                </div>-->
<!--                                <div class="progress-bar bg-info" :style="'width: ' + compartment.occupancyPercent + '%;'"></div>-->
<!--                            </div>-->
<!--                        </b-col>-->
<!--                    </b-row>-->
<!--&lt;!&ndash;                        <div v-for="(compartment, index) in cropByCompartmentData.gh2" :key="index">-->
<!--                           <b-card>-->

<!--                              {{ compartment.nameCompartment }} <br>-->
<!--                               Percent occupancy: {{ compartment.occupancyPercent }} % <br>-->
<!--                           </b-card>-->
<!--                        </div>&ndash;&gt;-->
<!--                </b-col>-->
<!--            </b-row>-->
<!--        </b-card>-->
    </b-container>
</template>
<script>
import { dashboardProductionByStaged, dashboardGetSurfaceByCrop, dashboardGetCropByCompartment } from '@routes';

export default {
    data() {
        return {
            breadcrumb: {
                title: 'Dashboard',
                path: []
            },
            toastTitle: 'Dashboard',
            stages: [],
            cropDistributionData: [],
            cropByCompartmentData: [],
            totalProductionLines: 0,
            totalSurfaceByCrop: 0
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    mounted() {
        this.productionByStage();
        this.surfaceByCrop();
        this.cropByCompartment();
    },
    methods: {
        productionByStage() {
            this.isLoading = true;

            return this.axios.get(dashboardProductionByStaged()).then(response => {
                if (response.status === 200) {
                    const responseData = response.data;
                    this.stages = responseData.stages;
                    this.totalProductionLines =  responseData.total;

                } else {
                    return [];
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {

                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        surfaceByCrop() {
            this.isLoading = true;

            return this.axios.get(dashboardGetSurfaceByCrop()).then(response => {
                if (response.status === 200) {
                    this.cropDistributionData = response.data;
                } else {
                    return [];
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        cropByCompartment() {
            this.isLoading = true;

            return this.axios.get(dashboardGetCropByCompartment()).then(response => {
                if (response.status === 200) {
                    this.cropByCompartmentData = response.data.data;
                } else {
                    return [];
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        getCircleStyle(stage) {
            switch (stage) {
                case 'Planning':
                    return { backgroundColor: '#7fc1d5' };
                case 'Sowing':
                    return { backgroundColor: '#62a5ae' };
                case 'Germination':
                    return { backgroundColor: '#549da6' };
                case 'Tidal floor':
                    return { backgroundColor: '#46959f' };
                case 'Compartment transplant':
                    return { backgroundColor: '#388d98' };
                case 'Pollen harvest':
                    return { backgroundColor: '#298490' };
                case 'Emasculation Pollination':
                    return { backgroundColor: '#1b7c89' };
                case 'Harvest':
                    return { backgroundColor: '#0d7482' };
                case 'Extraction':
                    return { backgroundColor: '#006d7b' };
                case 'Completed':
                    return { backgroundColor: '#025a67' };
                default:
                    return '';
            }
        },
        getCropStyle(crop) {
            switch (crop) {
                case 'Aubergine':
                    return { backgroundColor: '#7f7fff', color: 'white', borderRadius: '5%' };
                case 'Pepper':
                    return { backgroundColor: '#FBB900', color: 'white',  borderRadius: '5%' };
                case 'Tomato':
                    return { backgroundColor: '#E74C3C', color: 'white',  borderRadius: '5%' };
                case 'Cucumber':
                    return { backgroundColor: '#76B729', color: 'white',  borderRadius: '5%' };
                case 'Empty':
                    return { backgroundColor: '#dddddd', color: 'black',  borderRadius: '5%' };
                default:
                    return '';
            }
        }
    },
    watch: {
    },
    computed: {
    }
};
</script>

<style>
.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #adadad;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    z-index: 1;
    font-weight: bold;
    cursor: pointer;
}

.stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    position: relative;
    width: 8%;
}

.circle--complete {
    background-color: var(--color-primary);
}

.name {
    margin-top: 10px;
    font-weight: 600;
}

.crop-distribution {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.greenhouse-row {
    display: flex;
    align-items: center;
    width: 100%;
}

.crop-name {
    width: 120px;
    font-size: 20px;
    font-weight: bold;
}

.crop-header {
    width: 20%;
    font-size: 18px;
    text-align: center;
    margin-right: 20px;
}

.crop-value {
    width: 20%;
    height: 60px;
    padding-top: 5px;
    margin-top: 10px;
    margin-right: 20px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    display: grid;
    align-items: center;
}

.crop-average {
    width: calc(80% + 60px);
    height: 60px;
    padding-top: 5px;
    margin-top: 10px;
    margin-right: 20px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    display: grid;
    align-items: center;
}
</style>